@use 'abstracts/variables';
@use 'abstracts/colors';
@use 'abstracts/shadows';

$box-shadow-normal: 0 4px 6px rgba(0, 0, 0, 0.3);
$box-shadow-hover: 0 4px 6px rgba(0, 0, 0, 0.4);

.ni-notification-toast {
  position: relative;
  margin-bottom: 1rem;
  color: colors.$primary-text-color;
  box-shadow: $box-shadow-normal;
  cursor: auto;
  border-radius: variables.$border-radius;
  background: colors.$white;
  border: 0.1rem solid transparent;
  border-left: 0.8rem solid transparent;
  padding: 1rem 4rem 1rem 1.3rem;
  max-width: 80rem;

  &:hover {
    box-shadow: $box-shadow-hover;
  }

  &.info {
    border-color: map-get(colors.$info-palette, 500);
  }

  &.warning {
    border-color: map-get(colors.$warning-palette, 500);
  }

  &.error {
    border-color: map-get(colors.$danger-palette, 500);
  }
}

.toast-container {
  position: relative;
  cursor: auto;
  pointer-events: auto;
  top: 0 !important;
  right: 0 !important;
  padding: 0 0.8rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .toast-close-button {
    cursor: pointer;
    font-size: 2.5rem;
    font-weight: normal;
    width: 3rem;
    height: 2rem;
    position: absolute;
    top: 0.9rem;
    right: 0.6rem;
    text-shadow: none;
    opacity: 0.9;
    outline: none;
    display: flex;
    justify-content: center;

    &:focus,
    &:hover {
      color: colors.$primary-text-color;
      opacity: 1;
    }

    span {
      display: none;
    }
  }
}

.custom-toast-overlay-container {
  position: fixed;
  right: 0;
  top: 10rem;
  z-index: map-get(variables.$z-index-levels, 'max');
}

@media (min-width: map-get(variables.$grid-breakpoints, md)) {
  .ni-notification-toast {
    max-width: 60rem;
  }

  .toast-container {
    .toast-close-button {
      font-size: 2rem;
      width: 2rem;
      height: 2rem;
      top: 1.1rem;
      right: 1rem;
    }
  }

  .custom-toast-overlay-container {
    top: 14rem;
  }
}
