@use '../../abstracts/variables';
@use '../../abstracts/colors';
@use 'bootstrap' with (
  //--- Configuration
  $border-radius: variables.$border-radius,
  $border-color: variables.$form-border-color,

  //--- Typography
  $font-family-sans-serif: variables.$font-family-sans-serif,

  $font-size-root: variables.$font-size-root,
  $font-size-sm: variables.$font-size-sm,
  $font-size-base: variables.$font-size-base,
  $font-size-lg: variables.$font-size-lg,

  $font-weight-normal: variables.$font-weight-normal,
  $font-weight-medium: variables.$font-weight-medium,
  $font-weight-bold: variables.$font-weight-bold,

  //--- Color system
  $primary: map-get(colors.$primary-palette, 500),
  $body-color: colors.$default-text-color,
  $primary-text: colors.$primary-text-color,
  $headings-color: map-get(colors.$primary-palette, 500),
  $secondary: map-get(colors.$gray-palette, 500),
  //--- Forms
  $input-border-radius: variables.$border-radius,

  //--- Options
  $enable-cssgrid: true,

  //--- Grid
  $container-max-widths: variables.$container-max-widths,
  $grid-breakpoints: variables.$grid-breakpoints,

  //--- Spacing
  $spacers:
    (
      '0': 0,
      '0-5': 0.5rem,
      '1': 1rem,
      '1-5': 1.5rem,
      '2': 2rem,
      '2-5': 2.5rem,
      '3': 3rem,
      '3-5': 3.5rem,
      '4': 4rem
    )
);
@forward 'bootstrap';
