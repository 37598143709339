@mixin dialog-paddings($padding) {
  .modal-header {
    padding: $padding $padding 0;

    .modal-title {
      padding-bottom: 1.2rem;
    }
  }

  .modal-body {
    padding: 0.3rem $padding;
  }

  .modal-footer {
    padding: 0 $padding $padding;

    .modal-footer-content {
      padding-top: 1.2rem;
    }
  }
}
