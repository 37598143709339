@use 'abstracts/shadows';
@use 'abstracts/colors';
@use 'abstracts/variables';
@use '@styles/abstracts/mixins/container' as * with (
  $border-width: 0,
  $border-radius: variables.$border-radius-lg,
  $box-shadow: shadows.$shadow-default,
  $padding: 3rem
);

.ndp-section {
  @include container;
  display: block;
  background-color: colors.$white;
}
