$btn-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !default;

.ni-btn {
  --bg-color: #fff;
  --padding-x: 2.2rem;
  --padding-y: 2.2rem;
  --text-color: inherit;
  --border-radius: 0.6rem;
  --border-width: 1px;
  --border-color: #fff;
  --width: auto;
  --height: 3.8rem;
  --outline-width: 0.3rem;
  --outline-color: #fff;
  --font-size: inherit;
  --font-weight: bold;
  --icon-margin-x: 0.7rem;
  --opacity: 1;

  width: var(--width);
  height: var(--height);
  padding: var(--padding-y) var(--padding-x);
  background-color: var(--bg-color);
  color: var(--text-color);
  border-width: var(--border-width);
  border-color: var(--border-color);
  border-radius: var(--border-radius);
  border-style: solid;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  outline: none;
  box-shadow: 0 0 0 var(--outline-width) var(--outline-color);
  font-size: var(--font-size);
  font-weight: var(--font-weight);
  white-space: nowrap;
  transition: $btn-transition;
  opacity: var(--opacity);

  &:disabled,
  &.disabled,
  fieldset:disabled & {
    pointer-events: auto;
    cursor: not-allowed;
    box-shadow: none;
  }

  &-icon {
    &-left {
      margin-right: var(--icon-margin-x);
    }

    &-right {
      margin-left: var(--icon-margin-x);
    }
  }
}

.ni-icon-btn {
  --bg-color: #fff;
  --padding-x: 1.3rem;
  --padding-y: 1.3rem;
  --text-color: inherit;
  --border-radius: 0.6rem;
  --border-width: 0;
  --border-color: #fff;
  --width: 2rem;
  --height: 2rem;
  --outline-width: 0;
  --outline-color: #fff;

  width: var(--width);
  height: var(--height);
  padding: var(--padding-y) var(--padding-x);
  background-color: var(--bg-color);
  color: var(--text-color);
  border-width: var(--border-width);
  border-color: var(--border-color);
  border-radius: var(--border-radius);
  border-style: solid;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  outline: none;
  box-shadow: 0 0 0 var(--outline-width) var(--outline-color);
  transition: $btn-transition;

  &:active {
    border: 0;
  }

  &:hover,
  &:focus-visible {
    cursor: pointer;
  }

  &.disabled {
    cursor: not-allowed;

    &:hover {
      box-shadow: none;
    }
  }
}
