$size: 24px !default;

.ni-icon {
  user-select: none;
  background-repeat: no-repeat;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: $size;
  width: $size;
  overflow: hidden;
  fill: currentColor;

  * {
    fill: currentColor;
  }

  &.ni-icon-inline {
    font-size: inherit;
    height: inherit;
    line-height: inherit;
    width: inherit;
  }
}
