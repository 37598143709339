@use 'abstracts/variables';
@use 'abstracts/colors';

body {
  background-color: map-get(colors.$primary-palette, 50);
}

*:focus-visible {
  outline-color: variables.$focus-color;
  outline-width: variables.$focus-width;
}
