@use '@styles/abstracts/mixins' as gMixins;

$overlay-z-index: 99999999999 !default;

.ui-screen-locker {
  .fullscreen-block {
    @include gMixins.blur();
    @include gMixins.appearance-animation(1.5s);
    display: block;
    height: 100%;
    left: 0;
    opacity: 0;
    overflow: hidden;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: $overlay-z-index;
  }
}

@keyframes parts-animation {
  0% {
    transform: rotate(0);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes circles-animation {
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(180deg);
  }
}
