@use '@styles/abstracts/palettes' as gPalettes;

@mixin clickable-text() {
  opacity: 0.9;

  &:hover {
    text-shadow: 1px 1px 1px map-get(gPalettes.$gray-palette, 300);
    opacity: 1;
  }

  &:active {
    text-shadow: 1px 1px map-get(gPalettes.$gray-palette, 300),
      -1px 0 1px map-get(gPalettes.$gray-palette, 300);
  }
}
