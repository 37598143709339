@use '../abstracts/variables';

$fonts-path: variables.$fonts-path;
$font-weight-normal: variables.$font-weight-normal;
$font-weight-medium: variables.$font-weight-medium;
$font-weight-bold: variables.$font-weight-bold;

@font-face {
  font-family: 'DM';
  src: url('#{$fonts-path}/dm-sans/DMSans-Regular.ttf');
  font-weight: $font-weight-normal;
  font-style: normal;
}

@font-face {
  font-family: 'DM';
  src: url('#{$fonts-path}/dm-sans/DMSans-Medium.ttf');
  font-weight: $font-weight-medium;
  font-style: normal;
}

@font-face {
  font-family: 'DM';
  src: url('#{$fonts-path}/dm-sans/DMSans-Bold.ttf');
  font-weight: $font-weight-bold;
  font-style: normal;
}
