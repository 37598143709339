@use '../abstracts/variables';
@use '../abstracts/colors';
@use 'ui/buttons/src/styles/main';

.ni-btn {
  --padding-x: 2.2rem;
  --padding-y: 0;
  --border-radius: #{variables.$form-control-min-height * 0.5};
  --border-width: 1px;
  --border-color: #{map-get(colors.$primary-palette, 500)};
  --width: auto;
  --height: #{variables.$form-control-min-height};
  --font-size: #{variables.$font-size-base};
  --font-weight: #{variables.$font-weight-bold};
  --icon-margin-x: 0.7rem;
  --outline-width: 0;
  --outline-color: #{variables.$focus-color};

  &:hover,
  &:focus,
  &:focus-visible {
    --outline-width: #{variables.$focus-width};
  }

  &.btn-primary {
    --text-color: #{colors.$white};
    --bg-color: #{map-get(colors.$primary-palette, 500)};

    &:hover,
    &:focus-visible {
      --bg-color: #{map-get(colors.$primary-palette, 600)};
    }

    &:disabled,
    &.disabled {
      --bg-color: #{map-get(colors.$primary-palette, 200)};
      --border-color: #{map-get(colors.$primary-palette, 300)};
    }
  }

  &.btn-secondary {
    --text-color: #{map-get(colors.$primary-palette, 500)};
    --bg-color: #{colors.$white};
    --border-color: #{map-get(colors.$primary-palette, 500)};

    &:disabled,
    &.disabled {
      --text-color: #{map-get(colors.$gray-palette, 600)};
      --bg-color: #{map-get(colors.$gray-palette, 100)};
      --border-color: #{map-get(colors.$gray-palette, 100)};
    }
  }
}

.ni-icon-btn {
  --bg-color: colors.$white;
  --padding-x: 0.3rem;
  --padding-y: 0.3rem;
  --text-color: #{map-get(colors.$primary-palette, 300)};
  --border-radius: #{variables.$border-radius};
  --border-width: 0;
  --width: 2.8rem;
  --height: 2.8rem;
  --outline-width: 0;

  &:hover,
  &:focus-visible {
    --text-color: #{map-get(colors.$primary-palette, 600)};
    --outline-width: 0.3rem;
    --outline-color: #{variables.$focus-color};
  }
}
