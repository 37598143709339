$border-width: 1px !default;
$border-radius: 0.6rem !default;
$box-shadow: none !default;
$padding: 1rem !default;
$width: auto !default;
$height: auto !default;

@mixin container(
  $_border-width: $border-width,
  $_border-radius: $border-radius,
  $_box-shadow: $box-shadow,
  $_padding: $padding,
  $_width: $width,
  $_height: $height
) {
  display: flex;
  align-items: center;
  justify-content: center;
  width: $_width;
  height: $_height;
  padding: $_padding;
  border-width: $_border-width;
  box-shadow: $_box-shadow;
  border-radius: $_border-radius;
}
