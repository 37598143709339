@use 'abstracts/colors';

//--- Global
$border-radius: 0.4rem;
$border-radius-lg: 1rem;

//--- Paths
$context-path: '';
$assets-path: '#{$context-path}/assets';
$images-path: '#{$assets-path}/images';
$fonts-path: '#{$assets-path}/fonts';
$fa-font-path: '#{$fonts-path}/icons';

//--- Typography
$font-family-sans-serif: 'DM', sans-serif;

$font-size-root: 10px;
$font-size-sm: 1.4rem;
$font-size-base: 1.6rem;
$font-size-lg: 2rem;

$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

//--- Forms
$form-control-min-height: 4.8rem;
$focus-width: 0.3rem;
$focus-color-opacity: 0.25;
$focus-color: rgba(map-get(colors.$primary-palette, 500), $focus-color-opacity);
$focus-error-color: rgba(map-get(colors.$danger-palette, 500), $focus-color-opacity);
$form-border-color: map-get(colors.$gray-palette, 400);

$control-disabled-bg-color: map-get(colors.$gray-palette, 100);
$control-error-border-color: map-get(colors.$danger-palette, 500);

//--- Grid
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);
$mobile-header-height: 6.8rem;
$desktop-header-height: 8rem;
$mobile-footer-height: 7rem;
$desktop-footer-height: 0;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px
);

//--- Utils
$z-index-levels: (
  'min': 0,
  'l50': 500,
  'l100': 1000,
  'l200': 2000,
  'l300': 3000,
  'max': 99999999999
);
