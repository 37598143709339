@use 'abstracts/variables';
@use 'abstracts/colors';
@use 'abstracts/shadows';
@use '@styles/abstracts/functions' as gFunctions;
@use 'dialogs/src/styles/abstracts/mixins' as gDialogsMixins;
@use 'dialogs/src/styles/main' as gDialogs with (
  $modal-border-radius: variables.$border-radius-lg,
  $modal-content-box-shadow: shadows.$shadow-default,
  $close-btn-color: map-get(colors.$primary-palette, 700),
  $close-btn-hover-color: map-get(colors.$primary-palette, 400),
  $overlay-appearance-durations: 0.2s
);

.modal {
  @include gDialogsMixins.dialog-paddings(2rem);

  &-backdrop {
    background: rgb(map-get(colors.$gray-dark-palette, 500), 30%);

    &.show,
    &.fade {
    }
  }

  &-dialog {
    .modal-content {
      position: fixed;
      max-height: 90vh;
      left: 0;
      right: 0;
      bottom: -100%;
      border-radius: 2rem 2rem 0 0;
      border: 0;
      box-shadow: 0 3rem 9rem 0.4rem map-get(colors.$gray-dark-palette, 200);
      animation-duration: 0.2s;
      animation-fill-mode: forwards;
      animation-name: slide-up-animation;
    }
  }
}

@media (min-width: map-get(variables.$grid-breakpoints, md)) {
  .modal {
    @include gDialogsMixins.dialog-paddings(2.8rem);

    &-sm {
      width: 50rem;
    }

    &-md {
      width: 70rem;
    }

    &-lg {
      width: 110rem;
    }

    &-dialog {
      max-width: 180rem;
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: -10vh auto 0 auto;

      .modal-content {
        position: relative;
        left: auto;
        right: auto;
        bottom: auto;
        border-radius: variables.$border-radius-lg;
        box-shadow: shadows.$shadow-default;
      }
    }
  }
}

@keyframes slide-up-animation {
  from {
    bottom: -100%;
  }
  to {
    bottom: 0;
  }
}
