@use '@styles/abstracts/palettes' as gPalettes;

//--- Palettes
$primary-palette: gPalettes.$purple-palette;
$danger-palette: gPalettes.$red-palette;
$warning-palette: gPalettes.$yellow-palette;
$gray-palette: gPalettes.$gray-palette;
$gray-dark-palette: gPalettes.$gray-dark-palette;
$info-palette: gPalettes.$blue-palette;

//--- Colors
$black: #000;
$white: #fff;

$default-text-color: map-get($gray-dark-palette, 100);
$primary-text-color: map-get($gray-dark-palette, 500);
$danger-text-color: map-get($danger-palette, 500);
