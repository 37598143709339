@use 'abstracts/colors';
@use 'abstracts/variables';
@use '@styles/config/icons';

$control-start-icon-padding: 3rem;
$option-marked-color: map-get(colors.$primary-palette, 100);
$option-selected-color: map-get(colors.$primary-palette, 100);
$dropdown-option-disabled: map-get(colors.$gray-palette, 400);

@use '@ng-select/ng-select/scss/default.theme' with (
  $ng-select-primary-text: colors.$primary-text-color,
  $ng-select-dropdown-optgroup-text: colors.$primary-text-color,
  $ng-select-dropdown-option-text: colors.$primary-text-color,
  $ng-select-border-radius: variables.$border-radius,
  $ng-select-height: variables.$form-control-min-height,
  $ng-select-highlight: variables.$focus-color,
  $ng-select-selected: $option-selected-color,
  $ng-select-dropdown-option-disabled: $dropdown-option-disabled
);
@forward '@ng-select/ng-select/scss/default.theme';

.ctrl-wrapper-host {
  display: block;
}

.ctrl-wrapper {
  --label-color: #{map-get(colors.$primary-palette, 500)};
  --border-color: #{variables.$form-border-color};
  --outline-color: #{variables.$focus-color};
  --helper-text-color: #{map-get(colors.$gray-palette, 600)};

  display: block;
  margin-top: 0.3rem;
  min-height: variables.$form-control-min-height;

  .helper-text {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.875em;
    text-align: left;
    color: var(--helper-text-color);
  }

  .input-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    min-height: variables.$form-control-min-height;
  }

  .form-label {
    color: map-get(colors.$gray-palette, 600);
    position: absolute;
    top: 1.2rem;
    left: 1.5rem;
    max-width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    pointer-events: none;
    background-color: transparent;
    transform-origin: 0 0;
    margin-bottom: 0;
    border-radius: variables.$border-radius;
    z-index: map-get(variables.$z-index-levels, l50);
  }

  .form-label.active,
  .ng-select-opened ~ .form-label,
  .form-control:focus ~ .form-label {
    color: var(--label-color);
    line-height: 1;
    padding: 0 0.5rem;
    margin-left: -0.2rem;
    background-color: colors.$white;
    transition: all 0.2s ease-out;
    transform: translateY(-1.9rem) scale(0.8);
  }

  .form-control {
    color: colors.$primary-text-color;
    min-height: variables.$form-control-min-height;
    padding: 0.6rem 1.5rem;
    background-color: colors.$white;
    border-color: var(--border-color);

    &::placeholder {
      display: none;
    }

    &:focus {
      box-shadow: 0 0 0 variables.$focus-width var(--outline-color);
    }

    &:disabled {
      background-color: map-get(colors.$gray-palette, 100);

      ~ .form-label {
        color: map-get(colors.$gray-palette, 900);
      }
    }
  }

  .input-section-end {
    position: absolute;
    right: 1rem;
    display: flex;
    gap: 0.5rem;
  }

  .ng-select {
    width: 100%;

    &.ng-select-single {
      .ng-select-container {
        .ng-value-container {
          .ng-input {
            top: 7px;
          }
        }
      }
    }

    &.ng-select-focused {
      &:not(.ng-select-opened) {
        & > .ng-select-container {
          border-color: variables.$focus-color;
        }
      }

      .ng-select-container {
        box-shadow: 0 0 0 variables.$focus-width var(--focus-color);
      }

      &:not(.ng-select-opened) {
        & > .ng-select-container {
          border-color: var(--border-color);
        }
      }
    }

    .ng-select-container {
      padding: 5px 6px;
      border-color: var(--border-color);
      z-index: map-get(variables.$z-index-levels, l50);

      &:hover {
        box-shadow: none;
      }

      .ng-value-container {
        margin-top: 1px;

        .ng-input {
          & > input {
            color: colors.$primary-text-color;

            &:disabled {
              cursor: default;
            }
          }
        }
      }
    }

    span.ng-clear-wrapper {
      width: 14px;

      span.ng-clear {
        font-size: 2.2rem;
        vertical-align: bottom;
      }
    }

    .ng-arrow-wrapper {
      @extend .fa-solid;
      @extend .fa-chevron-down;
      padding-right: 9px;
      margin-top: 2px;

      .ng-arrow {
        display: none !important;
      }
    }

    &.ng-select-disabled {
      & > .ng-select-container {
        cursor: default;
        background-color: variables.$control-disabled-bg-color;
      }
    }

    &.ng-select-opened {
      & > .ng-select-container {
        z-index: map-get(variables.$z-index-levels, l50);

        .ng-arrow-wrapper {
          @extend .fa-solid;
          @extend .fa-chevron-up;
        }
      }
    }

    .ng-dropdown-panel {
      box-shadow: 0 0.9rem 0.8rem -0.7rem map-get(colors.$gray-palette, 700);
      z-index: map-get(variables.$z-index-levels, l100);

      &.ng-select-bottom {
        border-bottom-left-radius: variables.$border-radius;
        border-bottom-right-radius: variables.$border-radius;

        .ng-dropdown-panel-items {
          .ng-option {
            &:last-child {
              border-bottom-left-radius: variables.$border-radius - 1;
              border-bottom-right-radius: variables.$border-radius - 1;
            }
          }
        }
      }

      &.ng-select-top {
        border-top-left-radius: variables.$border-radius;
        border-top-right-radius: variables.$border-radius;

        .ng-dropdown-panel-items {
          .ng-option {
            &:last-child {
              border-top-left-radius: variables.$border-radius - 1;
              border-top-right-radius: variables.$border-radius - 1;
            }
          }
        }
      }

      .ng-dropdown-panel-items {
        margin-bottom: 0;

        .ng-option {
          text-align: left;
          padding: 0.4rem 1rem;
          white-space: normal;
          word-wrap: break-word;

          &.ng-option-marked {
            background-color: $option-marked-color;
          }

          &.ng-option-selected {
            background-color: $option-selected-color;
          }
        }

        &.scroll-host {
          &::-webkit-scrollbar-button {
            &:vertical {
              &:start {
                &:increment {
                  display: none;
                }
              }

              &:end {
                &:decrement {
                  display: none;
                }
              }

              &:decrement {
                display: none;
              }

              &:increment {
                display: none;
              }
            }
          }
        }
      }
    }

    //& ~ .form-label {
    //  top: 1.4rem;
    //}
  }

  &--error {
    --label-color: #{map-get(colors.$danger-palette, 500)};
    --border-color: #{map-get(colors.$danger-palette, 500)};
    --outline-color: #{variables.$focus-error-color};
    --helper-text-color: #{map-get(colors.$danger-palette, 500)};
  }
}

//--- Selects clear btn fix in MS browsers
input {
  &::-ms-clear {
    display: none;
  }
}
