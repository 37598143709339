@use '@styles/abstracts/mixins' as gMixins;
@use '@styles/abstracts/palettes' as gPalettes;

$modal-border-radius: 12px !default;
$modal-content-box-shadow: 0 5px 7px 0 map-get(gPalettes.$gray-palette, 600) !default;
$close-btn-color: map-get(gPalettes.$gray-dark-palette, 600) !default;
$close-btn-hover-color: map-get(gPalettes.$gray-dark-palette, 400) !default;
$overlay-appearance-durations: 0.5s !default;

.modal {
  overflow: hidden;

  &-backdrop {
    @include gMixins.blur();

    &.show {
      @include gMixins.appearance-animation($overlay-appearance-durations);
    }
  }

  &-container {
    display: flex;
    flex-direction: column;
    max-height: 90vh;
  }

  &-title {
    line-height: 1;
  }

  &-header {
    display: flex;
    flex-shrink: 0;
    align-items: flex-start;
    justify-content: space-between;
    border-bottom: 0;

    .close {
      @include gMixins.clickable-text();
      line-height: 1;
      border-width: 0;
      padding: 0;
      font-size: 2rem;
      color: $close-btn-color;
      opacity: 1;

      &:hover,
      &:active,
      &.active {
        box-shadow: none;
        color: $close-btn-hover-color;
      }
    }
  }

  &-body {
    overflow-y: auto;
    flex-grow: 1;
  }

  &-footer {
    border-top: 0;

    button + button {
      margin-left: 1rem;
    }
  }

  &-footer-content {
    display: flex;
    flex-shrink: 0;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
  }

  &-dialog {
    .modal-content {
      border-radius: $modal-border-radius;
      box-shadow: $modal-content-box-shadow;

      .ni-modal-dialog {
        .modal-open {
          .modal {
            overflow: hidden;
            overflow-y: scroll;

            &::-webkit-scrollbar {
              display: none;
            }
          }
        }
      }
    }
  }
}
