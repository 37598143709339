@use '@styles/abstracts/functions' as gFunctions;

@mixin blur() {
  background: gFunctions.alpha-transparency(#fff, 0.2);
  backdrop-filter: blur(2px);
}

@mixin appearance-animation($duration) {
  opacity: 0;
  animation-duration: $duration;
  animation-fill-mode: forwards;
  animation-name: overlay-appearance-animation;

  @keyframes overlay-appearance-animation {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}
